

.nav {
    width: 100%;
    height: $nav_height;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &--logo {
        position: absolute;
        left: 10px;
        & svg {
            height: 40px;
        }
    }

    &__list {
        min-width: 100%;
        display: flex;
        list-style: none;
        margin-right: 10px;
        justify-content: center;

        & li {
            margin: 0px 15px;
            position: relative;
            display: flex;
            justify-content: center;
            width: 100px;
            text-align: center;
            transition: all 0.1s;

            & a {
                width: 100%;
                height: 100%;
                text-decoration: none;
                color : $white ; 
                text-align: center;
            }

            &:hover{
                font-weight: 600;
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content : '' ; 
                position : absolute ; 
                width: 0%;
                height: 2px;
                border-radius: 50px;
                background-color: white;
                bottom: calc(0px - 10px);
                transition: width 0.2s;
            }
        }
    }

    &--login {
        margin: 0px 10px;
        background-color: $blue;
        padding: 5px 10px;
        border: none;
        border-radius: 20px;
        color: white;
        position: absolute;
        right: 10px;
        font-size: 15px;
        cursor: pointer;

    }
}