

.homepage {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: flex-end;

    &--introduction {
        width: 50%;
        position: absolute;
        top: 100px;
        right: 1%;
        display: flex;
        flex-direction: column;

        & h1, h2 {
            color: white;
            font-weight: 800;
            font-weight: normal;

        }

        & h1 {
            color: white;
            font-weight: 700;
            font-size: 40px;
            margin: 0;
            max-width: 600px;
        }

        & h2 {
            opacity: 0.8;
            font-size: 20px;
            max-width: 600px;
        }

        & button {
            width: 150px;
            background-color: #007aff;
            padding: 5px 10px;
            border: none;
            border-radius: 20px;
            color: #fff;
            font-size: 15px;
            cursor: pointer;
            background: rgb(116,71,208);
            background: linear-gradient(90deg, rgba(116,71,208,1) 0%, rgba(231,109,116,1) 100%);
        }
    }

    &--image {
        position: absolute;
        bottom: -10%;
        left: 50px;
        width: 50%;
        height: 110%;
        display: flex;
        align-items: flex-end;

        & img {
            height: 100%;
            //max-height: 700px;
        }
    }
}