

.applications {
    padding-top: 20px;
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    $introduction-height : 200px ; 

    &--introduction {
        height: $introduction-height;
        display: flex ; 
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & svg {
            height: 100px;
        }

        & h1 {
            margin: 10px 0px;
            color: white;
            font-weight: 400;
            font-size: 30px;
        }       
    }

    &__list {
        width: 100%;
        height: calc(100% - #{$introduction-height});
        max-width: 950px;
        

        & .swiper {
            height: calc(100% - 30px);
            padding-bottom: 30px;
        }

        & .swiper-pagination {
            & .swiper-pagination-bullet {
                height: 10px;
                width: 10px;
                background-color: rgb(197, 197, 197);
                opacity: 0.7;
            }

            & .swiper-pagination-bullet-active {
                background: $colourful-gradient;
            }
        }

        &--element {
            $list-element-padding-horizontal: 20px;
            $list-element-padding-vertical: 10px;
            width: calc(100% - $list-element-padding-horizontal * 2);
            height: calc(100% - $list-element-padding-vertical * 2);
            padding: $list-element-padding-vertical $list-element-padding-horizontal;

            & a {
                width: calc(100% - 40px);
                height: calc(100% - 20px);
                padding: 10px 20px;
                display: flex;
                text-decoration: none;
                background-color: #FAFAFA;
                border-radius: 20px;
                flex-direction: column;
                transition: transform 0.1s ease-in;

                &:hover {
                    transform: scale(1.05);
                }

                & .logo {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    justify-content: flex-end;

                    & svg {
                        height: 50px;
                    }
                }

                $h2-height : 30px ;
                $baseline-height : 30px ;

                & h2 {
                    height: $h2-height;
                    margin: 0;
                    color: #012038;
                }

                & #baseline {
                    height: $baseline-height;
                    margin: 0px 0px 10px 0px ;
                    color: #012038;
                    opacity: 0.9;
                }

                & p {
                    height: calc(100% - $h2-height - $baseline-height);
                    color: #012038;
                    opacity: 0.8;
                }



 
            }

        }
    }
}