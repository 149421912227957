
$font : "Montserrat", sans-serif ; 

// * COLOURS
$background-app : #011F35 ; 
$blue : #007aff ; 
$white : white ; 

$colourful-gradient : linear-gradient(90deg, rgba(116,71,208,1) 0%, rgba(231,109,116,1) 100%);

// * ELEMENTS
$nav_height : 50px; 