

* {
    font-family: $font;
}

body {
    background: rgb(1,31,53);
    background: linear-gradient(143deg, rgba(1,31,53,1) 5%, rgba(0,34,59,1) 32%, rgba(1,31,53,1) 72%, rgba(0,34,59,1) 100%);
    overflow: hidden;
}

html, body, #root {
    width: 100%;
    height: 100%;
    display: flex;
}

.app {
    height: 100%;
    width: 100%;

    &--container {
        padding-top: $nav_height;
        height: calc(100% - $nav_height);
    }
}

